.App {
  text-align: center;
  user-select: none;
}

.App-logo {
  pointer-events: none;
  width: 70%;
  max-width: 30rem;
  margin-bottom: 2rem;
  background-color: #ffffffdd;
  padding: 1.5rem;
  border-radius: 1rem;
}

.App-header {
  background-color: #3b71ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  animation: background-animation 4s infinite;
  -webkit-animation: background-animation 4s infinite;
}

@keyframes background-animation {
  0% {background-color: #3b71ff;}
  50% {background-color: #345fff;}
  100%{background-color: #3b71ff;}
}
